<template>
  <SignpostForm
    v-bind="{
      ...attributes,
      id,
      values,
      noSpinner,
      flowType: 'wc',
      isInWebApp: isTest,
      popup: isPopup,
      version,
      startingPage,
    }"
    @testmode="isTest = $event"
  />
</template>

<script>
import Vue from 'vue'

// import '@/sentryFlows'
import '@/helpers/prototypes'
import detectLocalStorage from '@/helpers/detectLocalStorage'
import getUrlDict from '@/helpers/getUrlDict'
import SignpostForm from '@/components/form/SignpostForm'

Vue.config.productionTip = false
Vue.config.performance = (detectLocalStorage() && localStorage.SavvyDebugMode) || false
Vue.config.devtools = (detectLocalStorage() && localStorage.SavvyDebugMode) || false

export default {
  name: 'Flow',
  components: {
    SignpostForm,
  },
  props: {
    id: String,
    values: {},
    noSpinner: Boolean,
    popup: [Boolean, String],
    version: Number,
  },
  data() {
    return {
      isTest: initialTestValue(),
      attributes: {},
      currentPageId: null,
      currentPageIndex: null,
      currentPageKey: null,
    }
  },
  computed: {
    isPopup() {
      return ![undefined, 'false', false, null].includes(this.popup)
    },
    startingPage() {
      if (this.currentPageId) return { type: 'id', value: this.currentPageId }
      if (this.currentPageIndex) return { type: 'index', value: this.currentPageIndex }
      if (this.currentPageKey) return { type: 'key', value: this.currentPageKey }
      return null
    },
  },
  created() {
    this.onMounted()
    this.isTest = initialTestValue()
  },
  methods: {
    onMounted() {
      const dict = getUrlDict()
      if (dict.current_page_id) this.currentPageId = dict.current_page_id
      if (dict.current_page_index) this.currentPageIndex = dict.current_page_index
      if (dict.current_page_key) this.currentPageKey = dict.current_page_key

      const host = document.querySelector(`savvy-flow[id="${this.id}"]`)
      const attrs = {}
      const hostAttributes = host.attributes
      if (hostAttributes) {
        // const start = 'value_'
        for (let i = 0; i < hostAttributes.length; i++) {
          const { nodeName, nodeValue } = hostAttributes[i]

          // if (nodeName.startsWith(start)) {
          attrs[nodeName] = nodeValue
          // }
        }
        this.attributes = attrs
      }
    },
  },
}

function initialTestValue() {
  const str = window.location.href.split('?')[1]

  const dict = str
    ? str
        .split('&')
        .map(part => part.split('='))
        .reduce((acc, item) => ({ ...acc, [item[0]]: item[1] }), {})
    : {}

  const isTest = dict.savvy_test === 'true'

  const isReflectTest = window.isReflectTest === true
  return isTest || isReflectTest
}
</script>

<style lang="scss">
// @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.css';
@import '@/styles/flows.scss';
</style>
