<template>
  <SignpostPopup
    class="Flow-EntireFlow FlowOuter"
    ref="root"
    :class="[`id-${id}`, `Builder-V${builderVersion}`]"
    :popup="isPopup"
    :open="open || popupOpen"
    @close="closePopup"
  >
    <div
      class="SignpostForm Flow-Element ElementType-FlowMain styleguide"
      ref="main"
      :class="[
        ...classValues,
        ...(builderVersion === 1 ? [`Builder-V1`] : []),
        `id-${id}`,
        `theme-${theme}`,
        `alignment-${alignment || 'left'}`,
        { loading: loadCycle, 'picker-mode-on': _getSelectionMode() },
      ]"
      style="position:relative;"
    >
      <v-style type="text/css">
        {{ styleText }}
      </v-style>
      <template v-if="!loadCycle">
        <FormProgress
          v-if="showProgressBar && !(builderVersion && builderVersion >= 3)"
          v-bind="{
            pages,
            userData,
            currentPageId,
            currentPageIndex,
            showPercentage,
            form,
          }"
          @reset="reset"
        />
        <FlowComponents
          class="GlobalLocation-BeforePage"
          location="before_page"
          :components="globalComponents"
          :page="currentPage"
          v-bind="{ form, userData, pageMeetsRequirements: currentPageMeetsReqs }"
          @prev="prev"
          @next="next"
          @reset="reset"
          @go-to-page="goToPage"
        />

        <template v-if="pages.length && currentPage">
          <!-- :key="currentPageId" -->
          <FormPage
            :page="currentPage"
            :pageData="userData"
            :pageId="currentPageId"
            :pageIndex="currentPageIndex"
            v-bind="{
              form,
              pages,
              userData,
              hasPrev,
              hasNext,
              hasFinish,
              fullDestinationUrl,
              responsiveGroups,
              components,
              isPopup,
              showHiddenComponents,
              pageMeetsRequirements: currentPageMeetsReqs,
            }"
            @update="onPageUpdate"
            @update-form="onFormPageUpdate(currentPage, $event)"
            @reset="reset"
            @next="next"
            @prev="prev"
            @go-to-page="goToPage"
          />
        </template>
        <template v-else-if="_getRegistered('editorAction')">
          <div
            style="height:400px;width:100%;display:flex;justify-content:center;align-items:center;"
          >
            <button
              class="border border-purple bg-purple text-white cursor-pointer py-2 px-6 rounded hover:opacity-70"
              style="color:white; pointer-events:all;"
              @click="_getRegistered('editorAction').modal('new-page')"
            >
              + Create your first page
            </button>
          </div>
        </template>
        <!-- :class="inPageClasses" -->
        <!-- page, componentTabGroupIndexes, pageMeetsRequirements -->
        <FlowComponents
          class="GlobalLocation-AfterPage"
          location="after_page"
          :components="globalComponents"
          :page="currentPage"
          v-bind="{ form, userData, pageMeetsRequirements: currentPageMeetsReqs }"
          @prev="prev"
          @next="next"
          @reset="reset"
          @go-to-page="goToPage"
        />

        <ComputedField
          v-for="computedField in computedFieldsToLoad"
          :key="computedField.id"
          v-bind="{ form, pages, userData, computedField }"
          @update="onComputedFieldUpdate(computedField.key || computedField.id, $event)"
        />
      </template>
      <Icon
        v-else-if="!noSpinner"
        spin
        style="display: block; margin: auto; height: 100%; min-height: 400px; font-size: 40px; stroke-width: 0; color: black"
        icon="fluent:spinner-ios-20-regular"
      />
      <div v-if="debugMode === 'visible'" class="debug-mode-visible">
        <pre>{{ JSON.stringify(userData, null, 2) }}</pre>
      </div>
      <div class="preload">
        <img v-for="url in pagePreload" :key="url" :src="url" />
      </div>
      <InfoBox
        :name="infoBoxKey"
        :form="form"
        :userData="userData"
        @update="onPageUpdate"
        @update-form="$emit('update-form', $event)"
        @reset="reset"
        @next="next"
        @prev="prev"
        @go-to-page="goToPage"
      />
      <button
        v-if="canShowFeedback"
        class="my-1 p-1 flex flex-col justify-center items-center text-xs border-none group absolute"
        @click="showFeedback = !showFeedback"
        style="right: 0; bottom: 0; pointer-events: all;"
      >
        <div
          class="w-10 h-10 m-1 flex justify-center items-center shadow rounded-full bg-white group-hover:shadow-md transition duration-150 ease-in-out"
          :class="{
            'bg-purple': showFeedback === 'options',
            'text-white': showFeedback === 'options',
          }"
        >
          <!-- Add icon into loadIconify.js when uncommented -->
          <!-- <Icon icon="tools" class="text-md transition duration-150 ease-in-out" /> -->
        </div>
        <div class="my-1">Feedback</div>
      </button>
      <!-- <div v-if="builderVersion >= 3 && isOffline" class="offline-indicator"> -->
      <div
        v-if="builderVersion >= 3 && isOffline"
        class="Flow-Element ElementType-OfflineIndicator"
      >
        You are currently offline.
      </div>
      <Feedback
        v-if="showFeedback"
        class="absolute"
        style="top: 0;"
        v-bind="{ id, form, currentPageId, pages, flowGroupId: groupId }"
      />
    </div>
  </SignpostPopup>
</template>

<script>
import Vue from 'vue'
// import * as Sentry from '@sentry/vue'

import cloneDeep from 'lodash/cloneDeep'
import isArray from 'lodash/isArray'
import debounce from 'lodash/debounce'
import set from 'lodash/set'
import isEqual from 'lodash/isEqual'
import mergeWith from 'lodash/mergeWith'
import range from 'lodash/range'

import { Database } from 'firebase-firestore-lite'
import Reference from 'firebase-storage-lite'
import Transform from 'firebase-firestore-lite/dist/Transform.js'

import jitsu from '@/boot-up/jitsu'
import notify from '@/helpers/notifyLite'
import loadIconify from '@/helpers/loadIconify'
import getUrlDict from '@/helpers/getUrlDict'
import detectLocalStorage from '@/helpers/detectLocalStorage'
import emitSyntheticEvent from '@/helpers/emitSyntheticEvent'
import getLocationData from '@/helpers/locationData'
import appNotification from '@/helpers/appNotification'
import emptyUserData from '@/helpers/emptyUserData'
import { lookup } from '@/helpers/flowApi'
import { unpack } from '@/helpers/computed'
import { cleanObj } from '@/helpers/removeEmpty'
import { parseURIComponent } from '@/helpers/textStringConversions'

import computedValues from './editor/helpers/computedValues'

// import debug from '@/helpers/debug'

import { generateElementSelectors } from './helpers/labelGenerator'
import { componentPassesValidation } from '@/components/form/helpers/validation'
import componentTypes, { defaultStyles } from '@/components/form/editor/helpers/componentTypes'
import repeatPage from '@/components/form/helpers/repeatPages'
import getParameterByName from '@/helpers/getParameterByName'

import compileStyleText, { groupSelectorsByCategory } from './helpers/compileStyleText'
import getReferrerData from './helpers/getReferrerData'
import conversionTrack from '@/components/form/helpers/conversionTrack'

import FormPage from './FormPage'
import ComputedField from './ComputedField'
import InfoBox from './InfoBox'
import SignpostPopup from './SignpostPopup'
import FlowComponents from './FlowComponents'

import {
  getDataToSend,
  createDestinationData,
  OUTPUTS_COLLECTION,
  dataOutputValid,
  createRefData,
  runCustomOutput,
  doExtraActions,
} from './helpers/dataOutputs'
import { passesConditions } from './helpers/conditions'

import Icon from './Icon.vue'

const db = new Database({ projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID })
const submissionDB = new Database({
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID_FLOW_SUBMISSION,
})

const CAN_USE_LOCAL_STORAGE = detectLocalStorage()

Vue.component('v-style', {
  render: function(createElement) {
    return createElement('style', this.$slots.default)
  },
})

Vue.filter('computed', function(text, userData, form) {
  return computedValues(userData, text, form)
})

emitSyntheticEvent('savvy.begun', {})

export default {
  name: 'SignpostForm',
  components: {
    Icon,
    FormProgress: () => import('./FormProgress'),
    Feedback: () => import('@/components/form/editor/mainEditor/Feedback.vue'),
    FormPage,
    SignpostPopup,
    InfoBox,
    ComputedField,
    FlowComponents,
  },
  provide() {
    return {
      _setRedirectPopup: this.setRedirectPopup,
      _track: this.track,
      _finish: this.finish,
      _isInWebApp: () => this.isInWebApp,
      _getFlowType: () => this.flowType,
      _isPreview: () => this.isPreview,
      _onUploadFile: this.onUploadFile,
      _loadedIcons: () => this.loadedIcons,
      _emitUpdateForm: this.emitUpdateForm,
      _validationFailed: () => this.validationFailed,
      _triggerInfoBox: this.triggerInfoBox,
      _sendIndividualDataOutput: this.sendIndividualDataOutput,
      _setCheckpoint: this.updateUserCheckpoints,
      _localDataGet: k => this.tempUserData[k],
      _localUpdate: ([k, v]) => this.$set(this.tempUserData, k, v),
      _closePopup: this.closePopup,
      _filterByConditions: this.filterByConditions,
      _updateUserData: this.updateUserData,
      _resetUserData: this.reset,
      _getBuilderVersion: () => this.builderVersion,
      _getFlowVersionNumber: () => this.flowVersionNumber,
      _uniqueByKey: this.removeDuplicatesByKeyReducer,
      _getFlowPages: visibleOnly => (visibleOnly ? this.pages : this.allFlowPages),
      _goToPage: this.goToPage,
      _scrollToTop: this.scrollToTop,
      _elSel: this.elSel,
      _getComponentElement: this.getComponentElement,
    }
  },
  inject: {
    _inspectData: { default: () => () => {} },
    _inspectMode: { default: () => () => {} },
    _toggleInspect: { default: () => () => {} },
    _setInspectData: { default: () => () => {} },
    _getSelectionMode: { default: () => () => {} },
    _isInEditor: { default: () => () => false },
    _getPreviewMode: { default: () => () => true },
    _getRegistered: { default: () => () => {} },
  },
  props: {
    id: String,
    userDataProp: Object,
    flowType: String,
    isInWebApp: { type: Boolean, default: false },
    isPreview: { type: Boolean, default: false },
    formProp: Object,
    values: {},
    noSpinner: {
      type: Boolean,
      default: getParameterByName('nospinner'),
    },
    popup: Boolean,
    popupOpen: Boolean,
    version: {},
    showHidden: Boolean,
    showHiddenComponents: Boolean,
    startingPage: Object,
  },
  data() {
    return {
      flowReady: false,
      savedForm: null,
      userData: {},
      tempUserData: {},
      groupId: null,
      entryId: null,
      prevEl: null,
      popupUrl: null,
      responsiveGroups: [],
      loadedIcons: null,
      debugMode: null,
      locationData: null,
      validationFailed: false,
      infoBoxKey: null,
      referrerData: null,
      hasMounted: false,
      open: false,
      prefilledKeys: new Set(),
      loadCycle: true,
      flowMeta: null,
      flowVersionNumber: null,
      showFeedback: false,
      canShowFeedback: false,
      isOffline: false,
      computedFieldsReadyToLoad: false,
    }
  },
  computed: {
    builderVersion() {
      if (this.form && typeof this.form.builder_version === 'number') {
        return this.form.builder_version
      }
      if (
        (this.form && this.form.builder_version === 2) ||
        (this.flowMeta &&
          (this.flowMeta.createdAt > new Date('2021-06-15') || this.flowMeta.builderVersion === 2))
      )
        return 2
      return 1
    },
    isPopup() {
      return this.popup || (this.form && this.form.popup)
    },
    inspectMode() {
      return this._inspectMode()
    },
    computedUserData() {
      return { ...this.userData }
    },
    currentPageId() {
      const currentPageId = this.userData && this.userData.currentPageId
      return currentPageId !== undefined
        ? currentPageId
        : this.pagesHaveLoaded && this.pagesHaveLoaded[0] && this.pagesHaveLoaded[0].id
    },
    form() {
      return this.formProp || this.savedForm
    },
    responsiveForm() {
      function customizer(objValue, srcValue) {
        if (isArray(objValue) || isArray(srcValue)) {
          const length = Math.max((objValue || []).length, (srcValue || []).length)

          return range(0, length).map((n, index) =>
            mergeWith(objValue[index], srcValue[index], customizer)
          )
        }
      }

      let form = cloneDeep(this.form) || {}

      if (!form._responsive) form._responsive = {}
      // form._responsive.default = cloneDeep(form)
      // if (form._responsive.default._responsive) delete form._responsive.default._responsive

      Object.entries(form._responsive || {}).forEach(([_width, f]) => {
        const width = parseInt(_width.slice(1))

        if (this.responsiveGroups.includes(width)) form = mergeWith(form, f, customizer)
      })

      // delete form._responsive

      return form
    },
    ...unpack('responsiveForm', ['theme', 'alignment']),
    showProgressBar() {
      if (!this.form || !this.form.progressBarType) return true
      return this.form.progressBarType === 'bar'
    },
    showPercentage() {
      return this.form && this.form.showProgressPercentage
    },
    currentPageIndex() {
      return this.pages.findIndex(page => page.id === this.currentPageId)
    },
    currentPage() {
      return this.pages[this.currentPageIndex]
    },
    currentFullPage() {
      return (this.form && this.allFlowPages.find(p => p.id === this.currentPageId)) || null
    },
    pagePreload() {
      const page = this.currentPage
      if (page && Array.isArray(page.preloadImages)) {
        // const list = []
        // return page.preloadImages.map(url => {
        //   const img = new Image()
        //   // img.onload = () => list.splice(i, 1)
        //   img.src = url
        //   return img
        // })
        return Array.from(new Set(page.preloadImages.map(u => u).filter(u => u)))
      }
      return []
    },
    currentPageMeetsReqs() {
      return this.componentsAreValid((this.currentPage && this.currentPage.components) || [])
    },
    currentPageIsFinish() {
      return this.currentPage && this.currentPage.isFinish
    },
    nextPageIsFinish() {
      if (!this.hasNext) return false
      return this.pages[this.currentPageIndex + 1] && this.pages[this.currentPageIndex + 1].isFinish
    },
    allFlowPages() {
      const pages = (this.responsiveForm && this.responsiveForm.pages) || []
      return this._getPreviewMode() && pages.some(p => p.repeater_key !== undefined)
        ? pages.reduce((acc, p) => {
            acc.push(...repeatPage(p, this.userData[p.repeater_key]))
            return acc
          }, [])
        : pages
    },
    visiblePages() {
      if (this.loadCycle) return []
      const allPages = this.allFlowPages
      const pages = this.showHidden
        ? allPages
        : allPages.filter(this.filterByConditions).reduce(this.removeDuplicatesByKeyReducer, [])
      const nonStaticComponentTypes = new Set(
        [...componentTypes(), { key: 'AddressBox', group: 'User Input' }]
          .filter(c => !['Layout', 'Static Display', 'Savvy Presets'].includes(c.group))
          .map(c => c.key)
      )

      const hidePrefilled = this.form && this.form.hide_prefilled_pages
      const currentPageId =
        (this.userData && this.userData.currentPageId) || (pages[0] && pages[0].id)
      return pages.reduce((acc, page) => {
        const isCurrentPage = page.id === currentPageId
        if (!isCurrentPage && !hidePrefilled) {
          acc.push(page)
          return acc
        }
        const allComponents = page.components || []

        const components = this.showHiddenComponents
          ? allComponents
          : allComponents
              .filter(this.filterByConditions)
              .map(b =>
                b.buttons ? { ...b, buttons: b.buttons.filter(this.filterByConditions) } : b
              )
        // .reduce(this.removeDuplicatesByKeyReducer, [])

        if (this.form && this.form.hide_prefilled_pages) {
          const nonStaticComponents = components.filter(c => nonStaticComponentTypes.has(c.type))
          const allComponentsPrefilled =
            nonStaticComponents.length > 0
              ? nonStaticComponents.every(c => this.prefilledKeys.has(c.key || c.id))
              : false
          const hidden = allComponentsPrefilled && this.componentsAreValid(nonStaticComponents)
          if (!hidden) acc.push({ ...page, components })
        } else acc.push({ ...page, components })

        return acc
      }, [])
    },
    pages() {
      if (!this.flowReady) return []
      // return this.showHidden ? this.responsiveForm.pages || [] : this.visiblePages
      return this.visiblePages
    },
    pagesHaveLoaded() {
      if (!this.flowReady) return null
      return this.pages
    },
    currentTotalPages() {
      if (this.pagesHaveLoaded) return this.pages.length
      else return 0
    },
    components() {
      const pages =
        (this.allFlowPages || []).reduce(this.removeDuplicatesByKeyReducer, []).map(page => ({
          ...page,
          components: (page.components || [])
            .map(b => (b.buttons ? { ...b, buttons: b.buttons } : b))
            .reduce(this.removeDuplicatesByKeyReducer, []),
        })) || []

      const components = pages.reduce((acc, p) => {
        p.components.forEach(c => (acc[c.key] = c))
        return acc
      }, {})

      return components
    },
    globalComponents() {
      const components = (this.responsiveForm && this.responsiveForm.components) || []
      return this.showHiddenComponents ? components : components.filter(this.filterByConditions)
    },
    hasPrev() {
      return this.currentPageIndex > 0
    },
    hasNext() {
      return this.currentPageIndex < this.pages.length - 1
    },
    hasFinish() {
      return Boolean(this.fullDestinationUrl)
    },
    destinations() {
      return (
        (this.form &&
          (this.form.destinations || []).filter(destination =>
            passesConditions(
              destination.conditions,
              this.userData,
              this.components,
              this.isInWebApp
            )
          )) ||
        []
      )
    },
    fullDestinationUrl() {
      if (this.destinations[0]) {
        const userData = cloneDeep(this.userData)

        delete userData.currentPageId

        let [url, queryString] = (this.destinations[0].url || '').split('?')

        const newQueryString = (queryString || '')
          .split('&')
          .concat([`data=${encodeURIComponent(JSON.stringify(userData))}`])
          .join('&')

        return `${url}/?${newQueryString}`
      } else return null
    },
    styles() {
      const styles = (this.form && this.form.styles) || {}
      if (this.builderVersion >= 3) {
        const baseStyles = defaultStyles(this.builderVersion)
        return mergeWith(baseStyles, styles)
      }
      return styles
    },
    selectorOrder() {
      if (this.builderVersion >= 3) {
        const selectors = Object.keys(this.styles)
        return groupSelectorsByCategory(selectors)
      }
      return (this.form && this.form.selectorOrder) || []
    },
    styleText() {
      return compileStyleText(this.selectorOrder, this.styles, this.id, this.isInWebApp)
    },
    csvUpload() {
      return (this.userData && this.userData.csv_upload) || null
    },
    userDataLogrocket() {
      if (!this.id_logrocket_user) return null
      return {
        email: this.userData.email,
        name: this.userData.name,
        first_name: this.userData.first_name,
        last_name: this.userData.last_name,
        company: this.userData.company,
      }
    },
    classValues() {
      const allKeys = new Set()
      const allowedComponentTypes = new Set(
        [...componentTypes(), 'AddressBox'].filter(c => c.group === 'User Input').map(c => c.key)
      )
      if (this.form && Array.isArray(this.allFlowPages)) {
        this.allFlowPages.forEach(p => {
          if (Array.isArray(p.components))
            p.components.forEach(c => {
              if (c.set_value_class && allowedComponentTypes.has(c.type)) allKeys.add(c.key)
            })
        })
      }
      if (this.form && this.form.computedFields) {
        this.form.computedFields.forEach(c => {
          if (c.set_value_class) allKeys.add(c.key)
        })
      }
      return Array.from(allKeys)
        .filter(k => this.userData[k] !== undefined)
        .map(k => `field-value-${k}-${this.userData[k]}`)
    },
    userDataToStore() {
      // const allKeys = new Set()
      const keysToDeleteFirebase = []
      const keysToDeleteLocalStorage = []
      if (this.form && Array.isArray(this.allFlowPages)) {
        this.allFlowPages.forEach(p => {
          if (Array.isArray(p.components))
            p.components.forEach(c => {
              // allKeys.add(c.key)
              if (c.doNotSave === 'cloud') keysToDeleteFirebase.push(c.key)
              else if (c.doNotSave) {
                keysToDeleteFirebase.push(c.key)
                keysToDeleteLocalStorage.push(c.key)
              }
            })
        })
      }
      if (this.form && this.form.computedFields) {
        this.form.computedFields.forEach(c => {
          // allKeys.add(c.key)
          if (c.doNotSave === 'cloud') keysToDeleteFirebase.push(c.key)
          else if (c.doNotSave) {
            keysToDeleteFirebase.push(c.key)
            keysToDeleteLocalStorage.push(c.key)
          }
        })
      }
      const cloneTrim = data =>
        Object.entries(cloneDeep(data)).reduce((acc, [k, v]) => {
          acc[k] = typeof v === 'string' ? v.trim() : v
          return acc
        }, {})
      const localStorageData = cloneTrim(this.userData)
      const firebaseData = cloneTrim(this.userData)

      /* Not sure why this is here - commented out to fix prefilled keys going missing */
      // if (this.form && this.form.embed_code_keys) {
      //   const embedCodeKeys = Array.isArray(this.form.embed_code_keys)
      //     ? this.form.embed_code_keys
      //     : this.form.embed_code_keys.split('\n').map(t => t.trim())

      //   embedCodeKeys.forEach(k => {
      //     if (!allKeys.has(k)) {
      //       delete firebaseData[k]
      //       delete localStorageData[k]
      //     }
      //   })
      // }

      keysToDeleteFirebase.forEach(k => {
        delete firebaseData[k]
      })
      keysToDeleteLocalStorage.forEach(k => {
        delete localStorageData[k]
      })
      return { local: localStorageData, firebase: firebaseData }
    },
    finalUserDataFirebase() {
      if (!this.form) return []
      const exceptions = ['createdAt', 'entryId', 'is_test', 'has_submitted']
      const data = getDataToSend(this.form, this.userData).filter(k => !exceptions.includes(k))
      const finalUserData = data.reduce((acc, key) => {
        acc.push([key, this.userDataToStore.firebase[key] || ''])

        return acc
      }, [])

      exceptions.forEach(k => {
        if (k === 'entryId') finalUserData.unshift([k, this.userData[k] || this.entryId])
        else if (k === 'createdAt') finalUserData.unshift([k, new Date().toISOString()])
        else if (this.userData[k]) finalUserData.push([k, this.userData[k]])
      })

      return finalUserData
    },
    hasDatastream() {
      if (!Array.isArray(this.form && this.form.dataOutputs)) return false
      return this.form.dataOutputs
        .filter(this.filterByConditions)
        .some(e => e.output !== 'google-sheets' && e.datastream)
    },
    mountedAndLoaded() {
      return this.hasMounted && Boolean(this.savedForm)
    },
    computedFieldsToLoad() {
      return ((this.form && this.form.computedFields) || []).filter(
        cf =>
          !this.form.delay_computed_field_loading ||
          this.computedFieldsReadyToLoad ||
          (this.form.delay_computed_field_loading_ignore_list || []).includes(cf.key)
      )
    },
    debouncedTrackCompletedFlow() {
      return debounce(this.trackCompletedFlow, 250, { trailing: true, leading: false })
    },
    debouncedSetCheckpoint() {
      return debounce(this.updateUserCheckpoints, 250, { trailing: true, leading: false })
    },
    debouncedSendFinalData() {
      return debounce(this.sendFinalData, 250, { trailing: true, leading: false })
    },
    debounceUpdateStoredData() {
      return debounce(this.updateStoredData, 250, { trailing: true, leading: false })
    },
    debounceOnMouseoverInspect() {
      return debounce(this.onMouseoverInspect, 250, { trailing: true, leading: false })
    },
  },
  watch: {
    id: {
      handler(id) {
        this.idWatchHandler(id)
      },
      immediate: true,
    },
    form: {
      async handler(f) {
        const icons = await loadIconify(f)
        this.loadedIcons = icons
      },
      immediate: true,
    },
    mountedAndLoaded: {
      handler(v) {
        if (v) {
          jitsu.identify({ group_id: this.groupId })
          jitsu.set({
            group_id: this.groupId,
            flow_id: this.id,
            flow_version: this.flowVersionNumber,
            is_live_flow_version: '', // @TODO
          })
          this.loadNavigationWatcher()
        }
      },
      immediate: true,
    },
    useLocationData: {
      handler(v) {
        if (v && !this.locationData) this.loadLocationData()
      },
    },
    computedUserData: {
      handler(userData, old) {
        if (isEqual(userData, old)) return
        this.$emit('data-update', this.userData)
        if (this.form && this.form.emit_all_user_data_updates)
          emitSyntheticEvent('savvy.user_data_updated', { userData: this.userData })
      },
      deep: true,
      immediate: true,
    },
    userDataLogrocket: {
      handler(v, o) {
        if (!v) return
        if (isEqual(v, o)) return
        if (window.Logrocket) window.Logrocket.identify(this.entryId, { ...v })
      },
    },
    userDataToStore: {
      async handler(userData, old) {
        if (isEqual(userData, old)) return
        // if (this.currentPage && !this.currentPage.showNav) this.next()
        const options = { local: true, firebase: true }
        if (userData && old) {
          if (old.local && userData.local) {
            options.local = !isEqual(userData.local, old.local)
          }
          if (old.firebase && userData.firebase) {
            options.firebase = !isEqual(userData.firebase, old.firebase)
          }
        }
        const allowSubmit = !this.loadCycle
        this.debounceUpdateStoredData(options, allowSubmit)
      },
      deep: true,
    },
    userDataProp(userDataProp) {
      if (!isEqual(userDataProp, this.userData)) this.userData = userDataProp
    },
    currentPageId: {
      handler(id) {
        const pages = this.allFlowPages || []
        const page = pages.find(p => p.id === id)

        if (id) {
          jitsu.set({
            flow_page_id: id,
            flow_page_key: page && page.key,
            flow_page_index: this.currentPageIndex,
            flow_total_pages: this.pages.length,
          })
          jitsu.track('flowpage:viewed', { view_type: 'view' })
        }

        this.validationFailed = false
        emitSyntheticEvent('savvy.page_updated', { userData: this.userData })
        const prevHighest = this.userData && this.userData.highest_page_reached_id
        if (!prevHighest) {
          this.updateUserData('highest_page_reached_id', id)
          if (page) this.updateUserData('highest_page_reached_key', page.key)

          jitsu.set({
            max_page_id: id,
            max_page_key: page?.key,
            max_page_index: this.currentPageIndex,
          })
        } else {
          const cIdx = pages.findIndex(p => p.id === id)
          const pIdx = pages.findIndex(p => p.id === prevHighest)
          if (cIdx > pIdx) {
            this.updateUserData('highest_page_reached_id', id)
            if (page) this.updateUserData('highest_page_reached_key', page.key)

            jitsu.set({
              max_page_id: id,
              max_page_key: page?.key,
              max_page_index: this.currentPageIndex,
            })
          }
        }
      },
    },
    currentFullPage: {
      handler(p, old) {
        if (isEqual(p, old)) return
        if (p) {
          const now = new Date().toISOString()
          const pageCheckpoints = []
          if (!this.isPopup || !this.loadCycle) this.setViewedPages(p.key)
          if (!this.loadCycle) {
            if (p.checkpoints) p.checkpoints.forEach(c => pageCheckpoints.push(c))
            if (old) {
              const alreadyInteracted =
                Array.isArray(this.userData._checkpoints) &&
                this.userData._checkpoints.includes('interacted_with_flow')

              if (!alreadyInteracted) pageCheckpoints.push('interacted_with_flow')

              /* Old must be less index than p, this.form.pages */
              const oldIndex = this.allFlowPages.findIndex(pg => pg.id === old.id)
              const currentIndex = this.allFlowPages.findIndex(pg => pg.id === p.id)
              if (oldIndex < currentIndex) {
                if (Array.isArray(old && old.components)) {
                  const enableHSTracking = old.components.find(
                    c => c.input_type === 'email' && c.hsq_track
                  )
                  if (enableHSTracking) {
                    var _hsq = window._hsq || []
                    _hsq.push(['identify', { email: this.userData[enableHSTracking.key] }])
                    _hsq.push(['trackPageView'])
                  }
                }

                const onExitOutputs = old.outputs_oncomplete
                if (Array.isArray(onExitOutputs))
                  onExitOutputs.forEach(k => this.sendIndividualDataOutput(k, now))

                const onCompleteCheckpoints = old.on_complete_checkpoints
                if (Array.isArray(onCompleteCheckpoints))
                  onCompleteCheckpoints.forEach(c => pageCheckpoints.push(c))
                const onCompleteConversions = old.on_complete_conversions
                if (onCompleteConversions) {
                  conversionTrack(old, this.userData, this.form, `on_complete_conversions`)
                }
              }
            }
          }
          const onEnterOutputs = p.outputs_onload
          if (Array.isArray(onEnterOutputs)) {
            onEnterOutputs.forEach(k => this.sendIndividualDataOutput(k, now))
          }
          if (pageCheckpoints.length > 0) {
            this.debouncedSetCheckpoint(pageCheckpoints)
          }
        }
      },
    },
    visiblePages: {
      handler(pages) {
        if (!pages) return
        this.$emit('pagesUpdate', pages)
      },
      immediate: true,
    },
    pagesHaveLoaded: {
      handler(pages) {
        if (!pages) return
        if (pages.length && this.currentPageIndex === -1) {
          this.updateUserData('currentPageId', pages[0].id)
        }
        // if (!isEqual(pages, oldPages)) this.$emit('pagesUpdate', this.visiblePages)

        if (
          !this.computedFieldsReadyToLoad &&
          this.form &&
          this.form.delay_computed_field_loading
        ) {
          // console.log('START TIMER')
          setTimeout(() => {
            // console.log('END TIMER')
            this.computedFieldsReadyToLoad = true
          }, parseInt(this.form.delay_computed_field_loading))
        } else {
          this.computedFieldsReadyToLoad = true
        }
      },
      // immediate: true,
    },
    destinations: {
      handler(destinations) {
        this.$emit('destinationsUpdate', destinations)
      },
      immediate: true,
    },
    responsiveForm: {
      handler(responsiveForm) {
        this.$emit('responsiveForm', responsiveForm)
      },
      immediate: true,
    },
    flowVersionNumber: {
      handler(v) {
        if (typeof v === 'number') {
          this.updateUserData('last_version', v)
          if (!this.userData.hasOwnProperty('first_version')) {
            this.updateUserData('first_version', v)
          }
        }
      },
      immediate: true,
    },
    responsiveGroups: {
      handler(responsiveGroups) {
        this.$emit('responsiveGroups', responsiveGroups)
      },
      immediate: true,
    },
    currentPageIndex: {
      handler(currentPageIndex) {
        if (currentPageIndex > -1) {
          if (!this.loadCycle) {
            this.track('reached_flow_page', {
              pageIndex: currentPageIndex,
              pageId: this.currentPageId,
            })
            this.updateUserData('current_page_index', currentPageIndex, true)
            if (this.currentPage && this.currentPage.key)
              this.updateUserData('current_page_key', this.currentPage.key, true)
          }

          const oldMax = this.userData.highest_page_reached_index
          const newMax = Math.max(oldMax, currentPageIndex) || currentPageIndex
          this.updateUserData('highest_page_reached_index', newMax)
          this.addExtraComputedUserData(newMax)
          // if (!this.finishInProgress) {
          // const useHighestPage = newMax > oldMax
          // const location = useHighestPage ? 'highest-page' : 'current-page'
          // const locationValue = useHighestPage ? `${newMax}` : `${currentPageIndex}`
          // if (!this.loadCycle) this.debouncedSendFinalData(true, { location, locationValue })
          // }
        }
        // else {
        //   let redirectFound = false
        //   const pages = this.allFlowPages || []
        //   const old = this.currentPageId
        //   if (!this.pagesHaveLoaded) return
        //   const previousPage = pages.find(p => p.id === old)
        //   if (previousPage) {
        //     const previousPageIndex = pages.findIndex(p => p.id === old)
        //     const availToGoBackTo = pages.slice(0, previousPageIndex)
        //     availToGoBackTo.reverse()
        //     const visiblePages = new Set(this.visiblePages.map(p => p.id))
        //     const redirectPage = availToGoBackTo.find(p => visiblePages.has(p.id))
        //     if (redirectPage) {
        //       this.updateUserData('currentPageId', redirectPage.id)
        //       redirectFound = true
        //     }
        //   }
        //   if (!redirectFound && this.pagesHaveLoaded && this.pagesHaveLoaded.length) {
        //     this.updateUserData('currentPageId', this.pagesHaveLoaded[0].id)
        //   }

        //   return
        // }
      },
      immediate: true,
    },
    currentTotalPages: {
      handler(currentTotalPages) {
        if (currentTotalPages) this.updateUserData('current_total_pages', currentTotalPages)
      },
      immediate: true,
    },
    csvUpload: {
      handler(csvUpload) {
        if (csvUpload && csvUpload.data && csvUpload.data1) {
          const data = { csvUpload }

          lookup('people_data_labs', 'enrich', { data })
        }
      },
      immediate: true,
    },
    styles: {
      handler() {
        this.syncResponsiveWatchers()
      },
      deep: true,
    },
    inspectMode: {
      handler(v) {
        if (v) {
          this.$refs.main.addEventListener('click', this.onClickInspect)
          this.$refs.main.addEventListener('mouseover', this.debounceOnMouseoverInspect)
        } else {
          this.$refs.main.removeEventListener('click', this.onClickInspect)
          this.$refs.main.removeEventListener('mouseover', this.debounceOnMouseoverInspect)
        }
      },
    },
  },
  created() {
    this.track('began_flow')
    // Sentry.setTag('flowId', this.id)
    if (
      (window.location.href.split('?')[1] || '').split('&').includes(`savvy_debug_mode=visible`)
    ) {
      this.debugMode = 'visible'
    }
  },
  mounted() {
    this.hasMounted = true
    window.addEventListener('savvy.navigation', e => console.log(e))
    emitSyntheticEvent('savvy.mounted', {})
    this.syncResponsiveWatchers()
    this.iconifyScript = document.createElement('script')
    this.iconifyScript.setAttribute('src', 'https://code.iconify.design/1/1.0.7/iconify.min.js')
    this.$refs.main.appendChild(this.iconifyScript)
    // this.loadNavigationWatcher()s
    window.addEventListener('online', this.onOnlineStatus)
    window.addEventListener('offline', this.onOfflineStatus)
    /* Add to window as SavvyFlows.updateUserData */
    window.SavvyFlows = { updateUserData: (k, v) => this.updateUserData(k, v) }
  },
  beforeDestroy() {
    if (this.nav_event_handler) this.$refs.main.removeEventListener('click', this.nav_event_handler)
    if (this.watchHandlerTimeout) clearTimeout(this.watchHandlerTimeout)
    if (this.styleTag) this.styleTag.remove()
    this.$refs.main.removeEventListener('click', this.onClickInspect)
    window.removeEventListener('click', this.popupHandler)
    this.$refs.main.removeEventListener('mouseover', this.debounceOnMouseoverInspect)
    window.removeEventListener('online', this.onOnlineStatus)
    window.removeEventListener('offline', this.onOfflineStatus)
    window.SavvyFlows = undefined
  },
  methods: {
    async idWatchHandler(id) {
      this.loadCycle = true
      if (this.watchHandlerTimeout) clearTimeout(this.watchHandlerTimeout)
      const maxAttempts = 2
      if (this.loadAttempts > maxAttempts) {
        if (!this.isInWebApp)
          notify(`Fetching form attempts > ${maxAttempts}: ${id}`, this.loadAttemptError, {
            form: this.form,
            userData: this.userData,
          })
        this.loadCycle = false
        return
      }
      try {
        this.loadUserData()
      } catch (error) {
        console.error(error)
        if (!this.isInWebApp)
          notify(`loadUserData`, error, { form: this.form, userData: this.userData })
      }
      let res
      let path
      let fallback = true
      const version = getUrlVersion(this.version)
      if (version === 'latest') {
        try {
          const docList = await db
            .ref(`forms/${id}/history`)
            .query({
              limit: 1,
              orderBy: { field: 'version', direction: 'desc' },
            })
            .run()
          if (docList[0]) {
            res = docList[0]
            fallback = false
          }
        } catch (error) {
          console.error(error)
        }
      }
      if (fallback) {
        const unsavedId = getUrlDict().unsaved
        if (unsavedId && this.flowType !== 'wc' && typeof version === 'number') {
          path = `forms/${id}/history/unsaved-version-${version}-${unsavedId}`
        } else
          path =
            typeof version === 'number' ? `forms/${id}/history/version-${version}` : `forms/${id}`
        try {
          res = await db.ref(path).get()
        } catch (err) {
          if (typeof version === 'number') {
            try {
              res = await db.ref(`forms/${id}`).get()
            } catch (error) {
              console.error(err)
              this.loadAttemptError = err
            }
          } else {
            console.error(err)
            this.loadAttemptError = err
          }
        }
      }
      if (!res) {
        this.loadAttempts = this.loadAttempts ? this.loadAttempts + 1 : 1
        const self = this
        this.watchHandlerTimeout = setTimeout(
          () => self.idWatchHandler(id),
          this.loadAttempts * maxAttempts * 1000
        )
        this.loadCycle = false
        return // this.idWatchHandler(id)
      }
      if (res.json_storage_location === 'kv') {
        const kvJson = await fetch(
          `https://flow-storage.heysavvy.workers.dev/flows/${id}@${version}`
        ).then(res => res.json())
        console.log('res.json_storage_location, kvJson', res.json_storage_location, kvJson)
        res.form = JSON.stringify(kvJson)
      }
      await this.onLoadSavedForm(id, res)
    },
    async onLoadSavedForm(id, res) {
      try {
        const savedForm = { id, pages: [], ...JSON.parse((res && res.form) || '{}') }
        this.savedForm = savedForm
        this.flowMeta = res
        if (this.flowMeta && this.flowMeta.form) delete this.flowMeta.form
        this.flowVersionNumber = res.version
        this.groupId = res && res.groupId
        this.productId = res && res.primaryProductId
        // if (res && typeof res.isLive === 'boolean') this.$emit('testmode', !res.isLive)
        if (savedForm && savedForm.fetch_contact_history) {
          try {
            this.referrerData = await getReferrerData(res.primaryProductId)
          } catch (error) {
            console.error(error)
            if (!this.isInWebApp)
              notify(`getReferrerData`, error, { form: this.form, userData: this.userData })
          }
        }
        if (this.savedForm.styles_raw) {
          const style = document.createElement('style')
          style.type = 'text/css'
          style.innerHTML = this.savedForm.styles_raw
          document.head.appendChild(style)
          this.styleTag = style
        }
        this.loadDefaultUserData()
        // Sentry.setTag('groupId', this.groupId || 'no group id')
        if (this.popup || savedForm.popup || this.isPopup)
          window.addEventListener('click', this.popupHandler)
        emitSyntheticEvent('savvy.ready', {})
        this.flowReady = true
        this.loadAttemptError = null
        await this.$nextTick()
      } catch (error) {
        console.error(error)
      }
      this.loadCycle = false
      await this.setStartingPage()
    },
    async onUploadFile({ file, onSuccess, key, multiple }) {
      const ref = new Reference(
        `gs://savvy-flow-assets/${this.id}/${file.name.split('.')[0]}_${(
          '_' + Math.random()
        ).slice(3)}.${file.name
          .split('.')
          .slice(1)
          .join('.')}`
      )
      const upload = ref.put(file) // returns an instance of UploadTask.

      // Will log the metadata when the upload is finished.

      // Handle errors.
      upload.catch(e => {
        console.error('error!', e)
        onSuccess(file, undefined, 'error')
        if (!this.isInWebApp) {
          notify(`Uploading file in Flow ${this.form.title || this.form.id}`, e, {
            form: this.form,
            userData: this.userData,
          })
        }
      })

      // Will be run when the promise resolves.
      upload.finally(() => {
        ref.getDownloadURL().then(url => {
          onSuccess(file, url, url ? 'done' : 'error')
          const fallback = multiple ? [] : {}
          const updateval = cloneDeep(this.userData[key] || fallback)

          if (multiple) {
            const val = Array.isArray(updateval) && updateval.find(f => f.name === file.name)
            if (val) val.url = url
            else updateval.push({ url, name: file.name })
          } else {
            updateval.url = url
          }
          const update = { [key]: updateval }
          this.onPageUpdate([0, update])
        })
      })
    },
    setRedirectPopup(url) {
      this.popupUrl = url
    },
    onClickInspect(e) {
      this._setInspectData(
        e.target,
        { boxShadow: this.prevEl.shadow, borderRadius: this.prevEl.borderRadius },
        this.prevEl
      )
      this._toggleInspect()
    },
    onMouseoverInspect(e) {
      if (this.prevEl) {
        this.prevEl.e.style.boxShadow = this.prevEl.shadow
        this.prevEl.e.style.borderRadius = this.prevEl.borderRadius
      }
      this.prevEl = {
        e: e.target,
        shadow: e.target.style.boxShadow,
        borderRadius: e.target.style.borderRadius,
      }
      e.target.style.boxShadow = '0 0 0 3px pink'
      e.target.style.borderRadius = '5px'
      this._setInspectData(
        e.target,
        { boxShadow: this.prevEl.shadow, borderRadius: this.prevEl.borderRadius },
        this.prevEl
      )
    },
    syncResponsiveWatchers() {
      this.responsive(this.$el, undefined, [this.$refs.main])
    },
    checkNode(node) {
      const start = 'savvy_flow_'
      const { nodeName, nodeValue } = node
      if (nodeName.startsWith(start)) {
        const propName = nodeName.slice(start.length)
        if (propName && nodeValue !== undefined) {
          const key = nodeName.slice(start.length)
          let value = nodeValue
          try {
            value = typeof nodeValue === 'string' ? JSON.parse(nodeValue) : nodeValue
          } catch (error) {
            value = nodeValue
          }
          this.updateUserData(key, value)
          this.setPrefilledKey(key, value)
        }
      }
    },
    closePopup() {
      this.open = false
      this.$emit('popup-update', false)

      this.manualTriggerOutputs('outputs_onclosepopup')
      // const firstPageId = this.pages && this.pages[0] && this.pages[0].id
      // if (this.form && this.form.reset_page_on_popup && firstPageId) {
      //   // delete this.userData.currentPageId
      //   this.updateUserData('currentPageId', firstPageId)
      // }
    },
    popupHandler(e) {
      if (!this.isPopup) return
      const selectorElements = document.querySelectorAll(
        this.flowType === 'sa' ? '.popup-opener' : this.form.popup_selector
      )
      selectorElements.forEach(async n => {
        if (n.contains(e.target)) {
          if (this.form && this.form.popup_prevent_default && e) {
            if (typeof e.preventDefault === 'function') e.preventDefault()
            if (typeof e.stopPropagation === 'function') e.stopPropagation()
          }
          if (this.form && this.form.reset_page_on_popup) {
            /* Clear Prefilled Keys in case first page is hidden via prefill */
            this.prefilledKeys.clear()
            this.loadAttrs()
            this.loadDefaultUserData()
            this.prefilledKeys = new Set(this.prefilledKeys)
            await this.$nextTick()
            const firstPageId =
              (this.pages && this.pages[0] && this.pages[0].id) || this.form.pages[0].id
            if (firstPageId) {
              this.updateUserData('currentPageId', firstPageId)
              await this.$nextTick()
            }
          }
          this.open = true
          const checkpoints = (this.userData && this.userData._checkpoints) || []
          const VIEWED_FLOW = 'viewed_flow'
          if (!checkpoints.includes(VIEWED_FLOW)) {
            this.track('checkpoint_triggered', { checkpoint: [VIEWED_FLOW] })
            this.manualTriggerOutputs('outputs_onviewflow')
            this.$set(
              this.userData,
              '_checkpoints',
              Array.from(new Set([...checkpoints, VIEWED_FLOW]))
            )
          }
          if (this.currentPage) this.setViewedPages(this.currentPage.key)

          const targetAttributes = e.target && e.target.attributes
          if (targetAttributes) {
            for (let i = 0; i < targetAttributes.length; i++) {
              this.checkNode(targetAttributes[i])
            }
          }

          const hostAttributes = n.attributes
          if (hostAttributes) {
            for (let i = 0; i < hostAttributes.length; i++) {
              this.checkNode(hostAttributes[i])
            }
          }

          const onEnterOutputs = this.form && this.form.outputs_onopenpopup
          const now = new Date().toISOString()
          if (Array.isArray(onEnterOutputs)) {
            onEnterOutputs.forEach(k => this.sendIndividualDataOutput(k, now))
          }
        }
      })
    },
    async loadLocationData() {
      const locationData = await getLocationData()
      this.updateUserData('location_data', locationData)
      this.locationData = locationData
    },
    setTestStatus(userData) {
      const dict = getUrlDict()
      const isTest =
        dict.savvy_test === 'false' ? dict.savvy_test : this.isInWebApp ? 'true' : dict.savvy_test
      if (dict.savvy_test) userData.is_test = isTest === 'true'
      else if (window.isReflectTest) userData.is_test = true
      else if (this.isInWebApp) userData.is_test = true
      if (dict.savvy_feedback === 'true') this.canShowFeedback = true
      if (!userData.createdAt) userData.createdAt = new Date().toISOString()

      const protectedKeys = new Set(['updatedAt', 'createdAt', 'updated_at', 'created_at'])
      if (this.values) {
        if (typeof this.values === 'string') {
          try {
            const values = JSON.parse(this.values || '{}')
            Object.entries(values).forEach(([k, v]) => {
              if (!protectedKeys.has(k)) userData[k] = v
            })
          } catch (error) {
            console.error(error)
            // if (!this.isInWebApp) Sentry.captureException(error)
          }
        } else {
          Object.entries(this.values).forEach(([k, v]) => {
            if (!protectedKeys.has(k)) userData[k] = v
          })
        }
      }

      const starter = 'savvy_value_'
      Object.entries(dict).forEach(([k, v]) => {
        const key = k.startsWith(starter) ? k.slice(starter.length) : ''
        if (!protectedKeys.has(k) && key) userData[key] = parseURIComponent(v)
      })

      const jsonStarter = 'savvy_values'
      const starterKey = dict[jsonStarter] ? dict[jsonStarter] : ''
      if (starterKey) {
        try {
          const parsed = parseURIComponent(starterKey)
          const st = JSON.parse(parsed)
          Object.entries(st).forEach(([k, v]) => {
            if (!protectedKeys.has(k)) userData[k] = v
          })
        } catch (error) {
          console.error(error)
          // Sentry.captureException(error)
        }
      }
    },
    loadUserData(id) {
      const item = (CAN_USE_LOCAL_STORAGE && localStorage.getItem('SavvyFormUserData')) || '{}'
      const userData = JSON.parse(item)[id || this.id] || {}

      this.entryId = this.entryId || userData.entryId || '_' + ('' + Math.random()).slice(2)

      userData.entryId = this.entryId

      jitsu.set({ entry_id: this.entryId, entryId: this.entryId })

      this.setTestStatus(userData)
      this.loadAttrs(userData)

      this.userData = userData
      this.$emit('dataUpdate', this.userData)
    },
    loadAttrs(userData) {
      const starter = 'value_'
      Object.entries(this.$attrs || {}).reduce((acc, e) => {
        const [k, v] = e
        if (k.startsWith(starter)) {
          const key = k.slice(starter.length)
          let value = v
          try {
            value = typeof v === 'string' ? JSON.parse(v) : v
          } catch (error) {
            value = v
          }
          this.setPrefilledKey(key, value)
          userData[key] = value
        }
        return acc
      }, {})
    },
    async loadDefaultUserData() {
      const urlparams = getUrlDict()
      if (
        (this.savedForm && this.savedForm.forget_user_data) ||
        urlparams.savvy_flow_reset === 'true'
      ) {
        const newUserData = emptyUserData(this.userData, this.savedForm || this.form)
        // const newUserData = {}
        this.setTestStatus(newUserData)
        this.userData = newUserData
        this.$emit('dataUpdate', this.userData)
      } else if (this.savedForm && this.savedForm.reset_page) {
        const newUserData = { ...(this.userData || {}) }
        delete newUserData.currentPageId
        this.userData = newUserData
      }

      const defaultData = (this.form && this.form.defaults && this.form.defaults.userData) || {}
      if (typeof defaultData === 'object') {
        Object.entries(defaultData).forEach(([k, v]) => {
          if (!this.userData.hasOwnProperty(k)) this.updateUserData(k, v)
        })
      }
      if (this.form && this.form.add_referrer) {
        const referrer = document.referrer
        if (referrer) {
          const referrers = this.userData._referrer || []
          referrers.push(referrer)
          this.updateUserData('_referrer', Array.from(new Set(referrers)))
        }
      }
      if (this.form && this.form.url_keys) {
        const urlKeys = Array.isArray(this.form.url_keys)
          ? this.form.url_keys
          : this.form.url_keys.split('\n').map(t => t.trim())
        urlKeys.forEach(k => {
          if (urlparams[k]) {
            const val = parseURIComponent(urlparams[k])
            let value = val
            try {
              value = typeof v === 'string' ? JSON.parse(val) : val
            } catch (error) {
              value = val
            }
            this.setPrefilledKey(k, value)
            this.updateUserData(k, value)
          }
        })
      }
      if (this.form && this.form.embed_code_keys) {
        const attrs = this.$attrs || {}
        const embedCodeKeys = Array.isArray(this.form.embed_code_keys)
          ? this.form.embed_code_keys
          : this.form.embed_code_keys.split('\n').map(t => t.trim())
        embedCodeKeys.forEach(k => {
          if (attrs[k]) {
            let value = attrs[k]
            try {
              value = typeof attrs[k] === 'string' ? JSON.parse(attrs[k]) : attrs[k]
            } catch (error) {
              value = attrs[k]
            }
            this.setPrefilledKey(k, value)
            this.updateUserData(k, value)
          }
        })
      }
      if (this.referrerData && typeof this.referrerData === 'object') {
        this.updateUserData('_source_data', transformReferrerData(this.referrerData))
      }

      if (this.form && this.form.use_location_data) {
        this.loadLocationData()
      }
      const startingCheckpoints = ['loaded_flow']
      if (!this.isPopup) {
        startingCheckpoints.push('viewed_flow')
        this.manualTriggerOutputs('outputs_onviewflow')
      }
      this.manualTriggerOutputs('outputs_onloadflow')
      // this.updateUserCheckpoints(startingCheckpoints)
      this.$set(
        this.userData,
        '_checkpoints',
        Array.from(new Set([...(this.userData._checkpoints || []), ...startingCheckpoints]))
      )
    },
    async setStartingPage() {
      if (this.startingPage) {
        await this.$nextTick()
        switch (this.startingPage.type) {
          case 'key': {
            this.updateUserData('current_page_key', this.startingPage.value)
            break
          }
          case 'id': {
            this.updateUserData('currentPageId', this.startingPage.value)
            break
          }
          case 'index': {
            this.updateUserData('current_page_index', this.startingPage.value)
            break
          }
        }
      }
    },
    loadNavigationWatcher() {
      const watchNav = this.form && this.form.emit_nav_events
      if (!watchNav) return

      if (!this.$refs.main) return
      if (this.nav_event_handler)
        this.$refs.main.removeEventListener('click', this.nav_event_handler)
      this.nav_event_handler = handleATagClick
      this.$refs.main.addEventListener('click', handleATagClick)
    },
    async updateStoredData(options, allowSubmit) {
      if (this.isPreview) return
      const { local: localData, firebase: firebaseData } = this.userDataToStore
      /* LocalStorage section */
      const useLocalStorage = CAN_USE_LOCAL_STORAGE && options && options.local
      const usefirestore = options && options.firebase
      const allStoredUserData =
        (useLocalStorage && JSON.parse(localStorage.getItem('SavvyFormUserData') || '{}')) || {}

      this.entryId =
        this.entryId ||
        (allStoredUserData[this.id] || {}).entryId ||
        '_' + ('' + Math.random()).slice(2)

      if (useLocalStorage) {
        localStorage.setItem(
          'SavvyFormUserData',
          JSON.stringify({
            ...allStoredUserData,
            [this.id]: { entryId: this.entryId, ...localData },
          })
        )
      }
      const userIds = Array.from(
        new Set(
          Object.entries((CAN_USE_LOCAL_STORAGE && localStorage) || {})
            .filter(([key]) => typeof key === 'string' && key.startsWith('SavvyUserId'))
            .map(([, value]) => value)
        )
      )
      const pulledOutDataKeys = [
        'email',
        'name',
        'first_name',
        'last_name',
        'nickname',
        'username',
        'phone',
        'company',
      ]
      const pulledOutData = {}
      pulledOutDataKeys.forEach(key => (pulledOutData[key] = this.userDataToStore.firebase[key]))
      jitsu.identify({
        ...pulledOutData,
        merged_data: JSON.stringify(this.userDataToStore.firebase),
        all_data: JSON.stringify({ [this.id]: this.userDataToStore.firebase }),
      })
      // jitsu.track('data_updated')

      /* Firebase section */
      if (usefirestore) {
        const replacer = (k, v) => {
          if (typeof v === 'number' && isNaN(v)) return 'NaN'
          if (v === Infinity) return 'Infinity'
          if (v === undefined) return 'Undefined'
          if (v && typeof v.then === 'function') return '[Pending Promise]'
          if (typeof v === 'function') return 'Function'
          if (typeof v === 'symbol') return 'Symbol'
          return v
        }

        const rawData = {
          userId: userIds[0],
          userIds,
          ...firebaseData,
          updatedAt: new Transform('serverTimestamp'),
        }

        if (firebaseData.submitted_timestamps)
          rawData.submitted_timestamps = new Transform(
            'appendToArray',
            firebaseData.submitted_timestamps
          )
        // const flowTitle = (this.flow && this.flow.title) || this.id
        const data = cleanObj(cloneDeep(rawData))
        try {
          await db.ref(`forms/${this.id}/entries/${this.entryId}`).update(data)
        } catch (e) {
          const errorIsExpected =
            e &&
            e.message &&
            e.message.indexOf &&
            e.message.startsWith(`No document to update`) !== -1

          let baseErrorMessage = `Saving to Entries`
          if (!errorIsExpected) {
            if (this.isInWebApp) console.error(e)
            else {
              try {
                notify(
                  `${baseErrorMessage} attempt update data: ${
                    e.response && e.response.data ? JSON.stringify(e.response.data) : e.message
                  } ${e}`,
                  null,
                  { form: this.form, userData: this.userData }
                )
                // Sentry.captureException(e, { extra: { data: rawData } })
              } catch (error) {
                // Sentry.captureException(error)
              }
            }
          }

          let attemptSet = true
          if (attemptSet) {
            const refData = { ...data }
            if (!refData.createdAt) refData.createdAt = new Transform('serverTimestamp')
            db.ref(`forms/${this.id}/entries/${this.entryId}`)
              .set(refData)
              .catch(async err => {
                if (this.isInWebApp) console.error(err)
                if (!this.isInWebApp) {
                  // try {
                  try {
                    const message = `${baseErrorMessage} attempt set data: ${err.message}`
                    if (err.message.includes('Cannot convert firestore.v1.Value with type unset')) {
                      const jsonData = JSON.stringify(this.userData, replacer)
                      notify(`Unset error.\n\n\`${jsonData}\``, null, {
                        form: this.form,
                        userData: this.userData,
                      })
                      // Sentry.captureException(err, { extra: { data: JSON.stringify(data) } })
                    } else {
                      notify(message, null, { form: this.form, userData: this.userData })
                      // Sentry.captureException(err, { extra: { data: JSON.stringify(data) } })
                    }
                  } catch (error) {
                    // Sentry.captureException(error)
                  }
                  // await db
                  //   .ref(`forms/${this.id}/entries/${entryId}`)
                  //   .set({ ...data, createdAt: new Transform('serverTimestamp') })
                  // } catch (error) {
                  // notify(`${baseErrorMessage} attempt set cleaned data: ${error.message}`, null, {
                  //   form: this.form,
                  //   userData: this.userData,
                  // })
                  // Sentry.captureException(error, { extra: { data } })
                  // }
                }
              })
          }
        }
        if (this.hasDatastream && allowSubmit) {
          try {
            await this.debouncedSendFinalData(true, {
              location: 'checkpoints',
              locationValue: this.userData._checkpoints || [],
            })
          } catch (error) {
            if (!this.isInWebApp) {
              notify(`Writing to submissions queue: ${error.message}`, error, {
                form: this.form,
                userData: this.userData,
              })
            }
          }
        }
        const emptyKeys = this.finalUserDataFirebase
          .filter(e => [undefined, null, ''].includes(e[1]))
          .map(e => e[0])
        const nonEmptyKeys = this.finalUserDataFirebase
          .filter(e => ![undefined, null, ''].includes(e[1]))
          .map(e => e[0])
        this.trackUserData({
          ...firebaseData,
          _empty_keys: emptyKeys,
          _non_empty_keys: nonEmptyKeys,
          updatedAt: new Date(),
        })
      }
    },
    async updateUserCheckpoints(checkpoint, datastream, sendAll) {
      const prevCheckpoints = Array.isArray(this.userData._checkpoints)
        ? this.userData._checkpoints
        : []
      const addedCheckpoints = Array.isArray(checkpoint) ? checkpoint : [checkpoint]
      const newCheckpoints = addedCheckpoints.filter(c => c && !prevCheckpoints.includes(c))
      const timestamp = new Date().toISOString()
      const checkpoints = Array.from(new Set([...prevCheckpoints, ...addedCheckpoints]))
      /* Trigger only non-datastream data outputs. Datastreams are handled in updateUserDataToStore */
      if (Array.isArray(this.form.dataOutputs)) {
        await Promise.all(
          this.form.dataOutputs.filter(this.filterByConditions).map(async e => {
            /* update later to support more types of triggers */
            if (!e.custom_trigger) return
            if (Array.isArray(e.triggers) && e.triggers.length > 0) {
              const triggerValues = e.triggers.reduce((acc, t) => {
                if (
                  t.condition === 'checkpoints' &&
                  !['!= / not in', 'not exists'].includes(t.operator)
                ) {
                  acc.push(...(Array.isArray(t.value) ? t.value : [t.value]))
                }
                return acc
              }, [])
              const isPrevCheckpoint = Array.isArray(triggerValues)
                ? triggerValues.every(v => !newCheckpoints.includes(v))
                : !newCheckpoints.includes(triggerValues)
              if (isPrevCheckpoint) return
            }
            const isValid = dataOutputValid(
              e,
              datastream || false,
              { location: 'checkpoints', locationValue: checkpoints, sendAll },
              this.userData,
              this.finalUserDataFirebase
            )
            if (!isValid) return
            return this.sendIndividualDataOutput(e, timestamp)
          })
        )
      }

      const checkpointConfig = (this.form && this.form.checkpoint_config) || {}

      this.updateUserData('_checkpoints', checkpoints)
      this.track('checkpoint_triggered', { checkpoint })
      newCheckpoints.forEach(c => {
        if (checkpointConfig && checkpointConfig[c] && checkpointConfig[c].send_notification) {
          this.sendAppNotification(c, this.productId)
        }
      })
    },
    onFormPageUpdate(page, [key, value]) {
      const pages = cloneDeep(this.form.pages)
      pages.find(c => c.id === page.id)[key] = value
      this.emitUpdateForm('pages', pages)
    },
    emitUpdateForm(k, v) {
      this.$emit('update-form', [k, v])
    },
    onPageUpdate([, value]) {
      Object.entries(value || {}).forEach(([k, v]) => {
        this.updateUserData(k, v)
      })
      // this.userData = { ...this.userData, ...value }
      this.onInteractWithFlow()
    },
    onInteractWithFlow() {
      const checkpoints = Array.isArray(this.userData._checkpoints)
        ? this.userData._checkpoints
        : []
      if (!checkpoints.includes('interacted_with_flow')) {
        this.debouncedSetCheckpoint(['interacted_with_flow'])
      }
    },
    onComputedFieldUpdate(key, value) {
      this.updateUserData(key, value)
      // this.userData = { ...this.userData, [key]: value }
    },
    prev() {
      try {
        if (this.hasPrev) {
          this.updateUserData('currentPageId', this.pages[this.currentPageIndex - 1].id)
        }
      } catch (error) {
        console.error(error)
        // if (!this.isInWebApp) Sentry.captureException(error)
      }
    },
    next(ignoreValidation) {
      if (!ignoreValidation && !this.currentPageMeetsReqs) {
        this.validationFailed = true
        return
      }
      try {
        let triggeredFinish = false
        const preChangeIndex = this.currentPageIndex
        this.validationFailed = false
        const nextPage = this.pages[preChangeIndex + 1]
        if (this.hasNext) {
          this.updateUserData('currentPageId', nextPage.id)
        } else if (this.hasFinish) {
          triggeredFinish = true
          this.finish()
        }

        /* If this is the last page, trigger */
        const lastPage = preChangeIndex === this.pages.length - 1
        /* If the next page is a finish page, trigger */

        const shouldFinish = lastPage || (nextPage && nextPage.isFinish)
        if (!triggeredFinish && shouldFinish) this.finish()
        if (this.preChangeIndex === this.currentPageIndex)
          throw new Error("Triggered next() but didn't change page index")
      } catch (error) {
        console.error(error)
        // if (!this.isInWebApp) Sentry.captureException(error)
      }
    },
    goToPage(key) {
      try {
        this.updateUserData('currentPageId', key)
      } catch (error) {
        console.error(error)
        // if (!this.isInWebApp) Sentry.captureException(error)
      }
    },
    track(eventId, data) {
      if (this.isPreview) return
      /* If flow does not belong to Savvy group, and not a standalone Flow / deployed, then do not send data */
      const skipSendData = this.groupId !== 'fxBRfFf86NatwbLBLgJ2' && !this.flowType
      if (skipSendData) return
      if (!data) data = {}
      data.flowId = this.id
      if ([true, 'true'].includes(this.userData.is_test)) data.is_test = true
      this.addToAnalyticsQueue('track', eventId, data)
        .then(() => {
          // console.log('Tracked the following event:', eventId, data)
        })
        .catch(error => {
          if (!this.isInWebApp) {
            // Sentry.captureException(error, { extra: data, level: 'warning' })
          }
          console.warn(
            'No Savvy Analytics found - failed to track the following event (+ error):',
            data,
            error
          )
        })
    },
    trackUserData(userData) {
      if (this.isPreview) return
      /* If flow does not belong to Savvy group, and not a standalone Flow / deployed, then do not send data */
      const skipSendData = this.groupId !== 'fxBRfFf86NatwbLBLgJ2' && !this.flowType
      if (skipSendData) return
      const doNotTrack = ['currentPageId', '_source_data']
      const coreFields = ['name', 'email', 'phoneNumber', 'firstName', 'lastName', 'nickname']

      const data = {}

      if ([true, 'true'].includes(this.userData.is_test)) {
        data.is_test = true // Savvy Analytics only accepts custom values in data.data for now, so this is lost - hence the 2nd line
        data.data = { is_test: true }
      }

      Object.entries(userData).forEach(([key, val]) => {
        if (doNotTrack.includes(key)) return

        set(data, `data.flows.${this.id}.${key}`, val)

        if (coreFields.includes(key) && (val || val === false)) set(data, key, val)
      })

      this.addToAnalyticsQueue('identify', undefined, data)
        .then(() => {
          // console.log('Identified the user with the following data:', data)
        })
        .catch(error => {
          if (!this.isInWebApp) {
            // Sentry.captureException(error, { extra: data, level: 'warning' })
          }
          console.warn(
            'No Savvy Analytics found - failed to identify the user with the following data + error:',
            data,
            error
          )
        })
    },
    addToAnalyticsQueue(methodName, ...args) {
      return new Promise((resolve, reject) => {
        let attempts = 0

        const done = doIt()
        if (done) {
          if (this.userData && this.userData._has_analytics === undefined)
            this.updateUserData('_has_analytics', true)
          return resolve()
        }

        const interval = setInterval(() => {
          const done = doIt()
          if (done) {
            clearInterval(interval)
            if (this.userData && this.userData._has_analytics === undefined)
              this.updateUserData('_has_analytics', true)
            return resolve()
          }
          if (attempts > 40) {
            clearInterval(interval)
            if (this.userData && this.userData._has_analytics === undefined)
              this.updateUserData('_has_analytics', false)
            return reject(
              `Savvy Analytics not found on ${window.location.host} - Timed out after 10 seconds`
            )
          }

          attempts++
        }, 250)

        function doIt() {
          const method = window.Savvy && window.Savvy[methodName]
          if (method) {
            method(...args)
          }
          return Boolean(method)
        }
      })
    },
    trackCompletedFlow() {
      this.track('completed_flow')
      this.completedFlow = true
      this.trackUserData({ completed_flow: true })
    },
    async sendAppNotification(event, productId) {
      try {
        await appNotification(this.id, event, this.userData, productId)
      } catch (error) {
        console.error('Error final completed notification', error)
        if (!this.isInWebApp) {
          notify(`Sending completed notification`, error, {
            form: this.form,
            userData: this.userData,
          })
        }
      }
    },
    async finish(ignoreDebounce) {
      if (this.finishInProgress || this.isPreview || !this.currentPageMeetsReqs) return
      this.finishInProgress = true
      try {
        // if (!this.hasDatastream)
        const method = !ignoreDebounce ? this.debouncedSendFinalData : this.sendFinalData
        await method(this.hasDatastream, { finish: true, sendAll: true })
        // const method = !ignoreDebounce ? this.debouncedSetCheckpoint : this.updateUserCheckpoints
        // await method('completed_flow', this.hasDatastream, true)
        this.debouncedTrackCompletedFlow()
        emitSyntheticEvent('savvy.completed_flow', { user_data: this.userData })
        await this.sendAppNotification('flow_completed', this.productId)
      } catch (error) {
        console.error('Error In sending final data!', error)
        if (!this.isInWebApp) {
          notify(`Sending final data`, error, { form: this.form, userData: this.userData })
        }
      }
      this.finishInProgress = false
      return true
    },
    async sendFinalData(datastream, options) {
      if (
        this.form.sheetUrl ||
        (this.form.dataOutputs && Object.keys(this.form.dataOutputs).length > 0)
      ) {
        const timestamp = new Date().toISOString()
        if (Array.isArray(this.form.dataOutputs)) {
          await Promise.all(
            this.form.dataOutputs.filter(this.filterByConditions).map(async e => {
              const canRun = dataOutputValid(
                e,
                datastream,
                options,
                this.userData,
                this.finalUserDataFirebase
              )
              if (!canRun) return
              if (datastream && e.output === 'custom') return
              return this.sendIndividualDataOutput(e, timestamp)
            })
          )
        }

        if (options && options.finish) {
          const prevCheckpoints = Array.isArray(this.userData._checkpoints)
            ? this.userData._checkpoints
            : []

          const COMPLETED_FLOW = 'completed_flow'
          if (!prevCheckpoints.includes(COMPLETED_FLOW)) {
            const checkpoints = Array.from(new Set([...prevCheckpoints, COMPLETED_FLOW]))
            this.updateUserData('_checkpoints', checkpoints)
          }
        }

        if (!datastream) {
          const submittedTimestamps = this.userData.submitted_timestamps || []
          submittedTimestamps.push(timestamp)
          this.updateUserData('submitted_timestamps', submittedTimestamps)
          this.updateUserData('has_submitted', true)
        }
      }
    },
    async sendIndividualDataOutput(keyOrOutput, timestamp, looped = new Set()) {
      if (this._isInEditor()) return
      if (!Array.isArray(this.form.dataOutputs)) return

      const output =
        typeof keyOrOutput === 'string'
          ? this.form.dataOutputs.find(o => o.id === keyOrOutput)
          : keyOrOutput
      if (!output) return
      if (looped.has(output.id)) return
      looped.add(output.id)
      if (output.output === 'custom') {
        return await runCustomOutput(output, this.userData, {
          setUserData: this.onComputedFieldUpdate,
          getComponentElement: this.getComponentElement,
          triggerAction: async (k, ts) => await this.sendIndividualDataOutput(k, ts, looped),
          resetUserData: this.reset,
          openInfoBox: this.triggerInfoBox,
        })
      }
      const data = { groupId: this.groupId, data: this.finalUserDataFirebase, formId: this.id }
      const destinationData = createDestinationData(output, data, this.form, this.userData)
      if (output.datastream || output.use_entry_data) {
        destinationData.data.entryId = this.entryId
        delete destinationData.data.data
      }
      const refData = createRefData(output, destinationData, this.id, this.entryId, timestamp)
      try {
        if (this.previousOutputs && this.previousOutputs[output.id]) {
          const record = await submissionDB
            .ref(`${OUTPUTS_COLLECTION}/${this.previousOutputs[output.id]}`)
            .set(refData)
          doExtraActions(output, destinationData.data)
          jitsu.track('triggered_action', {
            element_id: output.id,
            element_key: output.key,
            action_success: true,
            action_attempt: 0,
            action_type: output.output,
          })
          return record
        } else {
          try {
            const record = await submissionDB.ref(OUTPUTS_COLLECTION).add(refData)
            doExtraActions(output, destinationData.data)
            if (record) {
              this.previousOutputs = this.previousOutputs || {}
              this.previousOutputs[output.id] = record.id
            }
            jitsu.track('triggered_action', {
              element_id: output.id,
              element_key: output.key,
              action_success: true,
              action_attempt: 1,
              action_type: output.output,
            })
            return record
          } catch (error) {
            if (error.message.includes('Document already exists:')) {
              const id = error.message.split('/').slice(-1)[0]
              const record = await submissionDB
                .ref(`${OUTPUTS_COLLECTION}/${id}-${Math.round(Math.random() * 100000)}`)
                .set(refData)
              doExtraActions(output, destinationData.data)
              if (record) {
                this.previousOutputs = this.previousOutputs || {}
                this.previousOutputs[output.id] = record.id
              }
              jitsu.track('triggered_action', {
                element_id: output.id,
                element_key: output.key,
                action_success: true,
                action_attempt: 2,
                action_type: output.output,
              })
              return record
            } else {
              jitsu.track('triggered_action', {
                element_id: output.id,
                element_key: output.key,
                action_success: false,
                action_type: output.output,
              })
              throw error
            }
          }
        }
      } catch (err) {
        console.error(err)
        if (!this.isInWebApp) {
          notify(
            `Writing submission to Firebase failed for ${
              destinationData.output
            }\nHashed Datadump: ${btoa(JSON.stringify(refData))}\n\nError`,
            err,
            { form: this.form, userData: this.userData, datadump: true }
          )
        }
      }
    },
    filterByConditions(item) {
      if (item.hide) return false
      return passesConditions(
        item.conditions || [],
        this.userData,
        this.components,
        this.isInWebApp
      )
    },
    removeDuplicatesByKeyReducer(items, item) {
      return item.key && items.find(p => p.key === item.key) ? items : items.concat(item)
    },
    reset() {
      const userData = emptyUserData(this.userData, this.form)

      this.userData = userData
    },
    scrollToTop() {
      const useDocument = this.form.scroll_to_document_top
      let rootEl = (this.$refs.root && this.$refs.root.$el) || this.$refs.root
      if (useDocument) {
        rootEl = document.documentElement
      } else if (this.isPopup && rootEl && rootEl.querySelector) {
        const possibleNewRoot = rootEl.querySelector('.SavvyFlowPopup-container')
        if (possibleNewRoot) rootEl = possibleNewRoot
      }
      if (rootEl && rootEl.scrollIntoView)
        rootEl.scrollIntoView({ behavior: 'smooth', inline: 'start' })
    },
    responsive(element, breakpoints = [520, 720], extraElements = []) {
      const self = this

      function debounceUpdateBreakpoints() {
        return debounce(updateBreakpoints, 100, { trailing: true, leading: true })
      }

      const updateBreakpointsDebounced = debounceUpdateBreakpoints()

      if (window.ResizeObserver) new ResizeObserver(updateBreakpointsDebounced).observe(element)
      else {
        // fallback with MutationObserver if Resize Observer is not supported by the browser
        const observer = new MutationObserver(mutations =>
          mutations.forEach(
            mutation =>
              mutation.type === 'attributes' &&
              mutation.attributeName === 'style' &&
              updateBreakpointsDebounced()
          )
        )
        observer.observe(element, { attributes: true })
        document.body
          .querySelectorAll('*')
          .forEach(element => observer.observe(element, { attributes: true }))
        window.addEventListener('resize', updateBreakpointsDebounced, false)
        updateBreakpointsDebounced()
      }

      function updateBreakpoints() {
        const currentBreakpointGroups = breakpoints.filter(b => element.offsetWidth <= b)

        element.setAttribute('max-width', currentBreakpointGroups.map(b => `${b}px`).join(' '))
        extraElements.forEach(el =>
          el.setAttribute('max-width', currentBreakpointGroups.map(b => `${b}px`).join(' '))
        )

        self.responsiveGroups = currentBreakpointGroups
      }
    },
    triggerInfoBox(name) {
      this.infoBoxKey = name
    },
    setPrefilledKey(key, val) {
      this.prefilledKeys.add(key)
      const keys = Array.from(this.prefilledKeys).reduce((acc, k) => {
        acc[k] = (k === key && val) || this.userData[k] || null
        return acc
      }, {})
      this.updateUserData('_prefilled', keys)
    },
    componentsAreValid(components) {
      return components.every(c => componentPassesValidation(c, this.userData, this.form))
    },
    addExtraComputedUserData(newMax) {
      const includeArray = this.form && this.form.include_user_data_fields
      const include = Array.isArray(includeArray)
      if (!include) return
      includeArray.forEach(key => {
        let value = undefined
        switch (key) {
          case 'current_page_title': {
            value = this.currentPage && this.currentPage.title
            break
          }
          case 'highest_page_title': {
            value = this.pages[newMax] && this.pages[newMax].title
            break
          }
          case 'current_page_subtitle': {
            value = this.currentPage && this.currentPage.subtitle
            break
          }
          case 'highest_page_subtitle': {
            value = this.pages[newMax] && this.pages[newMax].subtitle
            break
          }
          case 'current_page_heading_label': {
            value = this.currentPage && this.currentPage.headingLabel
            break
          }
          case 'highest_page_heading_label': {
            value = this.pages[newMax] && this.pages[newMax].headingLabel
            break
          }

          default:
            break
        }
        if (value !== undefined) this.updateUserData(key, value)
      })
    },
    manualTriggerOutputs(location) {
      const now = new Date().toISOString()
      const outputs = this.form && this.form[location]
      if (Array.isArray(outputs)) outputs.forEach(k => this.sendIndividualDataOutput(k, now))
    },
    setViewedPages(pageKey) {
      const newIds = Array.from(new Set([...(this.userData._pages_viewed || []), pageKey]))
      this.updateUserData('_pages_viewed', newIds)
    },
    onOnlineStatus() {
      this.isOffline = false
    },
    onOfflineStatus() {
      this.isOffline = true
    },
    elSel(elementType) {
      return generateElementSelectors(elementType, this.currentPage)
    },
    updateUserData(k, v, direct, noRecurse) {
      if (direct) {
        this.$set(this.userData, k, v)
        return
      }
      switch (k) {
        case 'current_page_key': {
          const page = this.pages.find(p => p.key === v)
          if (page) {
            const val = page.id
            this.$set(this.userData, 'currentPageId', val)
          } else console.warn(`Page Key ${v} was not found`)
          break
        }
        case 'current_page_index': {
          const page = this.pages[v]
          if (page) {
            const val = page.id
            this.$set(this.userData, 'currentPageId', val)
          } else console.warn(`Page Index ${v} was not found`)
          break
        }

        default: {
          if (typeof k === 'object' && !noRecurse) {
            Object.keys(k).forEach(key => {
              this.updateUserData(key, k[key], direct, true)
            })
          } else {
            this.$set(this.userData, k, v)
          }
          // this.$set(this.userData, k, v)
          break
        }
      }
    },
    getComponentElement(key) {
      let rootEl = (this.$refs.root && this.$refs.root.$el) || this.$refs.root
      return rootEl.querySelector(`.ComponentKey-${key}`)
    },
  },
}

function getUrlVersion(propVersion) {
  const dict = getUrlDict()
  const version = dict.savvy_flow_version || propVersion
  switch (version) {
    case 'prod':
    case 'live':
      return undefined
    case 'latest':
      return version
    default:
      return Number(version) || undefined
  }
}

function handleATagClick(e) {
  const target = e.target
  if (target.tagName === 'A') {
    if (target.className.includes('CustomButton')) return

    const attributes = target.attributes
    const hrefValue = attributes.href && attributes.href.value
    if (hrefValue && hrefValue.startsWith('value_update')) return

    e.preventDefault()
    emitSyntheticEvent('savvy.navigation', { url: e.target.href })
  }
}

function transformReferrerData(referrerData) {
  const data = cloneDeep(referrerData)
  return data
}
</script>
